export const GET_LOUNGE_HOUR_API =
  "https://gw-qa.prikly.io/lounges/v1/shedules/shedules";

export const GET_APPOINTMENT_WEEK_API =
  "https://gw-qa.prikly.io/reservas/v1/Reserva/GetWeekReservations";

export const GET_CLIENTS_API =
  "https://gw-qa.prikly.io/customers/v1/Cliente/SearchCustomers";

export const GET_SERVICES_API =
  "https://gw-qa.prikly.io/servicios/v1/Services/GetServices";

export const GET_AVAILABLE_HOURS_API =
  "https://gw-qa.prikly.io/reservas/v1/Employee/ListAvailableEmployeeHours";

export const POST_CREATE_APPOINTMENT_API =
  "https://gw-qa.prikly.io/reservas/v1/Reserva/CreateReserve";

export const PUT_EDIT_APPOINTMENT_API =
  "https://gw-qa.prikly.io/reservas/v1/Reserva/EditReserve";

export const DELETE_CANCEL_APPOINTMENT_API =
  "https://gw-qa.prikly.io/reservas/v1/Reserva/CancelReserve";

export const GET_APPOINTMENT_STATES_API =
  "https://gw-qa.prikly.io/reservas/v1/Reserva/GetReservationStates";
