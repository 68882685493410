import { getAppointmentsOnTheWeekAdapter } from "@Adapters/getWeekAppointments/getWeekAppointments.adapter";
import sortReservationsByTime, {
  calculateTotalCollisions,
} from "@Components/ui/Calendar/components/Appointment/utils/eventCollision";
import {
  loadAppointmentsWeek,
  loadAppointmentsWeekCached,
  setAppointmentsFetchStatus,
} from "@ReduxService/slices/appointment/appointments.slice";
import { RootState } from "@ReduxService/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentStatesAdapter } from "@Adapters/getAppointmentStates/getAppointmentStates.adapter";

export function useGetAppointments() {
  const dispatch = useDispatch();
  const appointmentsCached = useSelector(
    (state: RootState) => state.appointments.appointmentWeekCached
  );

  useEffect(() => {
    const loadInitialData = async () => {
      await getAppointmentStatesAdapter(dispatch);
      await getAppointmentsAndUpdateCache({
        dispatch,
        appointmentsCached,
        day: new Date(),
      });
    };

    loadInitialData();
    // @eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const generateWeekDates = (baseDate: Date): Date[] => {
  const weekDates: Date[] = [];
  const startDate = new Date(baseDate);
  startDate.setDate(startDate.getDate() - startDate.getDay());

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    weekDates.push(currentDate);
  }

  return weekDates;
};

export const getAppointmentsAndUpdateCache = async ({
  dispatch,
  appointmentsCached,
  day,
}) => {
  const dayString = day.toDateString();
  const isDayCached = appointmentsCached.includes(dayString);

  if (isDayCached) return;

  const weekDays = generateWeekDates(day);
  const startDate = weekDays[0];
  const endDate = weekDays[6];

  const appointments = await getAppointmentsOnTheWeekAdapter({
    startDate,
    endDate,
  });

  if (appointments.isSuccess) {
    calculateTotalCollisions(appointments.appointments);
    sortReservationsByTime(appointments.appointments);
    dispatch(loadAppointmentsWeek(appointments.appointments));
    dispatch(
      loadAppointmentsWeekCached(weekDays.map((day) => day.toDateString()))
    );
    dispatch(
      setAppointmentsFetchStatus({
        status: "succeeded",
        error: null,
      })
    );
  } else {
    dispatch(
      setAppointmentsFetchStatus({
        status: "failed",
        error: appointments.message,
      })
    );
  }
};
