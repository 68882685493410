import useInitialState from "@Components/ui/CalendarFilter/hooks/initialStates";
import { CalendarIcon } from "@Models/icons";
import {
  TbChevronLeftPipe,
  TbChevronLeft,
  TbChevronRightPipe,
  TbChevronRight,
} from "react-icons/tb";
import formatDateToReadableString from "@Utilities/dates/formatDateToReadableString";
// @ts-ignore
import { Icon, IconWrapper } from "@viuti/recursos";
import { useEffect, useRef, useState } from "react";
import style from "./CustomDateSelector.module.css";

export const CustomDateSelector = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const calendarRef = useRef(null);
  const inputRef = useRef(null);

  const {
    dateFilter,
    changeDateFilter,
    incrementDateFilter,
    decrementDateFilter,
    incrementDateFilterWeek,
    changeFilterToPreviousWeek,
  } = useInitialState();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const formatDisplayDate = (date: string) => {
    if (isMobile) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
    return formatDateToReadableString(date);
  };

  const handleDateButtonClick = () => {
    setShowCalendar(!showCalendar);
    // Agregamos un pequeño delay para asegurar que el DOM se ha actualizado
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.showPicker();
      }
    }, 100);
  };

  const handleDateChange = (newDate: string) => {
    if (newDate) {
      changeDateFilter(newDate);
      setShowCalendar(false);
    }
  };

  return (
    <div className={style.dateSelector} ref={calendarRef}>
      <div className={style.controls}>
        <button
          className={style.arrowButton}
          onClick={() => changeFilterToPreviousWeek()}
          title="Semana anterior"
        >
          <IconWrapper icon={TbChevronLeftPipe} />
        </button>

        <button
          className={style.arrowButton}
          onClick={() => decrementDateFilter()}
          title="Día anterior"
        >
          <IconWrapper icon={TbChevronLeft} />
        </button>

        <div className={style.dateDisplayContainer}>
          <button className={style.dateDisplay} onClick={handleDateButtonClick}>
            <Icon path={CalendarIcon} size={16} color={"#4d4d4d"} />
            <span>{formatDisplayDate(dateFilter)}</span>
          </button>

          <input
            ref={inputRef}
            type="date"
            className={style.calendarInput}
            value={dateFilter}
            required
            onKeyDown={(e) => {
              if (e.key === "Backspace" || e.key === "Delete") {
                e.preventDefault();
              }
            }}
            onChange={(e) => handleDateChange(e.target.value)}
          />
        </div>

        <button
          className={style.arrowButton}
          onClick={() => incrementDateFilter()}
          title="Día siguiente"
        >
          <IconWrapper icon={TbChevronRight} />
        </button>

        <button
          className={style.arrowButton}
          onClick={() => incrementDateFilterWeek()}
          title="Semana siguiente"
        >
          <IconWrapper icon={TbChevronRightPipe} />
        </button>
      </div>
    </div>
  );
};
