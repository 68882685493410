import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@ReduxService/store";
import style from "./CalendarColumnsHeader.module.css";
import { useCalendarColumns } from "../../hooks/useCalendarColumns";
import { useGetDaysAndHoursToRender } from "../../hooks/getDaysAndHoursToShow";

interface CalendarColumnsHeaderProps {
  columnsContainerRef: React.RefObject<HTMLDivElement>;
  headerRef: React.RefObject<HTMLDivElement>;
}

export const CalendarColumnsHeader = ({
  columnsContainerRef,
  headerRef,
}: CalendarColumnsHeaderProps) => {
  const { columns } = useCalendarColumns();
  const viewMode = useSelector(
    (state: RootState) => state.calendarView.viewMode
  );
  const { daySelected } = useGetDaysAndHoursToRender();

  const selectedDate = new Date(daySelected);
  selectedDate.setDate(selectedDate.getDate() + 1);

  const dayInitial = selectedDate
    .toLocaleDateString("es-ES", { weekday: "long" })
    .charAt(0)
    .toUpperCase();
  const numberDay = selectedDate.getDate();
  const isToday = selectedDate.toDateString() === new Date().toDateString();

  useEffect(() => {
    const syncColumnWidths = () => {
      if (!headerRef.current || !columnsContainerRef.current) return;

      const columnElements = columnsContainerRef.current.querySelectorAll(
        `.${style.column}`
      );
      const headerColumns = headerRef.current.querySelectorAll(
        `.${style.headerColumn}`
      );

      columnElements.forEach((column, index) => {
        if (headerColumns[index]) {
          const width = column.getBoundingClientRect().width;
          (headerColumns[index] as HTMLElement).style.width = `${width}px`;
        }
      });
    };

    syncColumnWidths();
    window.addEventListener("resize", syncColumnWidths);
    return () => window.removeEventListener("resize", syncColumnWidths);
  }, [columnsContainerRef, columns]);

  return (
    <div className={style.calendarHeader} ref={headerRef}>
      <div className={style.hourHeaderColumn}>
        {viewMode === "day" && (
          <div className={style.dayHeader}>
            <span className={style.dayName}>{dayInitial}</span>
            <span className={isToday ? style.dayNumberToday : style.dayNumber}>
              {numberDay}
            </span>
          </div>
        )}
      </div>
      {columns.map((column) => (
        <div
          key={column.key}
          className={`${style.headerColumn} ${
            viewMode === "day" ? style.headerColumnDay : ""
          }`}
        >
          {viewMode === "day" ? (
            <div className={style.employeeHeader}>
              <span>{column.employee}</span>
            </div>
          ) : (
            <div className={style.dayHeader}>
              <span className={style.dayName}>
                {column.day
                  .toLocaleDateString("es-ES", { weekday: "short" })
                  .toUpperCase()}
              </span>
              <span
                className={
                  column.day.toDateString() === new Date().toDateString()
                    ? style.dayNumberToday
                    : style.dayNumber
                }
              >
                {column.day.getDate()}
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
